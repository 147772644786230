import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from 'utils/media';

export interface ModalImageProps {
  src?: string;
  alt?: string;
}

function ModalImage({ src, alt }: ModalImageProps) {
  const [toggleModal, setToggleModal] = useState(false);

  const modalHandle = () => setToggleModal((prev) => !prev);

  console.log(toggleModal);

  return (
    <>
      <img onClick={modalHandle} src={src} alt={alt} />

      {toggleModal && (
        <Modal onClick={modalHandle}>
          <div>
            <img onClick={(e) => e.stopPropagation()} src={src} alt={alt} />
          </div>
        </Modal>
      )}
    </>
  );
}

export default ModalImage;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  & div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & img {
    transform: scale(1.8);
    max-width: 70% !important;
  }

  ${media('<=phone')} {
    & img {
      transform: scale(1);
    }
  }
`;
